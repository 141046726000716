<template>
    <div v-if="!loading">
        <div class="font-medium text-lg flex">
            <span>{{product.name}}</span>
            <span v-if="product.active" class="ml-4 select-none inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800"> Active </span>
            <span v-if="!product.active" class="ml-4 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800"> Not active </span>
        </div>
        <div class="flex justify-between items-center">
            <div>
                <router-link to="/dashboard/product/list" class="mt-2 text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to product list</router-link>
            </div>
            <div>
                <router-link v-if="$store.state.userData.systemCreator || $store.state.userData.permissions.editingProducts" :to="`/dashboard/product/edit?id=${product.id}`" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                Edit
                <PencilIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </router-link>
                <div v-if="product.link.href.length>0" @click.prevent="visitExternalSource" class="ml-2 cursor-pointer inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                Visit external source
                <CursorClickIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                </div>
            </div>
        </div>
        <div class="mt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
        </div>
        <div class="mt-4 border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Photo</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div class="group block w-1/4 aspect-w-10 aspect-h-7 rounded-lg  focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-purple-500 overflow-hidden">
                            <img v-if="product.image.url.length>0" :src="product.image.url" class="object-cover pointer-events-none" />
                            <span class="select-none" v-if="product.image.url.length === 0">Not uploaded yet</span>
                        </div>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Model</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.model.length>0 ? product.model : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Manufacturer</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                        <span>{{product.manufacturer.name.length>0 ? product.manufacturer.name : "--"}}</span>
                        <BanIcon v-if="dataCorrectness.manufacturer !== undefined && !dataCorrectness.manufacturer.exists" class="ml-2 -mr-0.5 h-4 w-4 text-red-600" aria-hidden="true" />
                    </dd>
                    <span class="ml-2 text-xs text-gray-500 flex items-center" v-if="dataCorrectness.manufacturer !== undefined && dataCorrectness.manufacturer.newData !== null"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> Manufacturer was renamed to: {{dataCorrectness.manufacturer.newData}}.</span>
                    <span class="ml-2 text-xs text-gray-500 flex items-center" v-if="dataCorrectness.manufacturer !== undefined && !dataCorrectness.manufacturer.exists"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> This manufacturer was deleted.</span>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">EAN</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.ean.length>0 ? product.ean : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">SKU</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.sku.length>0 ? product.sku : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Category</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                        <span>{{product.category.name.length>0 ? product.category.name : "--"}}</span>
                        <BanIcon v-if="dataCorrectness.category !== undefined && !dataCorrectness.category.exists" class="ml-2 -mr-0.5 h-4 w-4 text-red-600" aria-hidden="true" />
                    </dd>
                    <span class="ml-2 text-xs text-gray-500 flex items-center" v-if="dataCorrectness.category !== undefined && dataCorrectness.category.newData !== null"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> Category was renamed to: {{dataCorrectness.category.newData}}.</span>
                    <span class="ml-2 text-xs text-gray-500 flex items-center" v-if="dataCorrectness.category !== undefined && !dataCorrectness.category.exists"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> This category was deleted.</span>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Description</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.description.length>0 ? product.description : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Attributes</h3>
        </div>
        <div class="mt-4">
            <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="product.attribs.list.length>0">
                <li v-for="(attrib) in product.attribs.list" :key="attrib.id" class="py-4">
                    <div class="flex items-center space-x-4">
                        <div class="flex-shrink-0">
                            <div :class="[ 'bg-yellow-200 flex-shrink-0 flex items-center justify-center w-16 p-0.5 rounded-md text-black text-sm font-medium select-none']">
                                {{attrib.parent.name.substring(0,3).toUpperCase()}}
                            </div>
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate">
                                <router-link :to="`/dashboard/modifyAttribute?attrib=${attrib.parent.id}`" target="_blank" class="text-xs opacity-50 hover:text-purple-500">({{attrib.parent.name}})</router-link> {{ attrib.name }} 
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                                ID: {{ attrib.id }}
                            </p>
                        </div>
                    </div>
                    <span class="text-xs" v-if="!attrib.exists">This attribute was deleted from <router-link class="text-purple-500 hover:text-purple-400" to="/dashboard/product/attributes" target="_blank" >groups of attributes</router-link>.</span>
                </li>
            </ul>
            <EmptyState v-if="product.attribs.list.length === 0"></EmptyState>
        </div>
        <div class="mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Storage</h3>
        </div>
        <div class="mt-4 border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Warehouse</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.storage.warehouse.name.length>0 ? product.storage.warehouse.name : "--"}}</span>
                        <BanIcon v-if="dataCorrectness.warehouse !== undefined && !dataCorrectness.warehouse.exists" class="ml-2 -mr-0.5 h-4 w-4 text-red-600" aria-hidden="true" />
                    </dd>
                    <span class="ml-2 text-xs text-gray-500 flex items-center" v-if="dataCorrectness.warehouse !== undefined && dataCorrectness.warehouse.newData !== null"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> Warehouse was renamed to: {{dataCorrectness.warehouse.newData}}.</span>
                    <span class="ml-2 text-xs text-gray-500 flex items-center" v-if="dataCorrectness.warehouse !== undefined && !dataCorrectness.warehouse.exists"> <InformationCircleIcon class="h-5 w-5 mr-2 text-blue-600" aria-hidden="true" /> This warehouse was deleted.</span>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Location</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.storage.location.length>0 ? product.storage.location : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Additional information</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.storage.additionalInfo.length>0 ? product.storage.additionalInfo : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Prices and costs</h3>
        </div>
        <div class="mt-4 border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Sell price</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.pricesAndCosts.sellPrice.toFixed(2)}} {{product.pricesAndCosts.currency}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Production cost</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.pricesAndCosts.productionCost.toFixed(2)}} {{product.pricesAndCosts.currency}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Complaint cost</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.pricesAndCosts.complaintCost.toFixed(2)}} {{product.pricesAndCosts.currency}}</span>
                    </dd>
                </div>
            </dl>
        </div>

        <div class="mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Extra fields</h3>
        </div>
        <div class="mt-4 border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Extra field 1</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.extraField1.length > 0 ? product.extraField1 : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Extra field 2</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.extraField2.length > 0 ? product.extraField2 : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="border-t border-gray-200">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
                    <dt class="text-sm font-medium text-gray-500 select-none">Extra field 3</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span>{{product.extraField3.length > 0 ? product.extraField3 : "--"}}</span>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="block mt-2">
            <span class="text-xs select-none text-gray-500">Product created by {{product.meta.createdBy}} at {{creationDate}}.</span>
            <span class="text-xs select-none text-gray-500"> Last update at {{modificationDate}}.</span>
        </div>

    </div>
</template>

<script>
    import { PencilIcon, CursorClickIcon, BanIcon, InformationCircleIcon } from '@heroicons/vue/outline'
    import moment from 'moment';
    import EmptyState from '../components/EmptyState.vue';
    export default {
        name: "ProductViewmode",
        data()
        {
            return {
                loading: true,
                dataCorrectness: {
                    category: {
                        exists: true,
                        newData: null
                    },
                    manufacturer: {
                        exists: true,
                        newData: null
                    },
                    warehouse: {
                        exists: true,
                        newData: null
                    }
                }
            }
        },
        components: {
            EmptyState,PencilIcon,CursorClickIcon,BanIcon,InformationCircleIcon
        },
        async created()
        {
            await this.getProduct();
        },
        computed:
        {
            creationDate()
            {
                return moment.unix(this.product.meta.createdDate.seconds).format("DD.MM.YYYY hh:mm");
            },
            modificationDate()
            {
                return moment.unix(this.product.meta.lastModificationDate.seconds).format("DD.MM.YYYY hh:mm");
            }
        },
        methods:
        {
            async checkIfActual(ref,actualValue)
            {
                let info = {}
                let result = await ref.get();
                info.exists = result.exists;

                if(result.exists === true)
                {
                    result = result.data()
                    if(this.isSame(result.name,actualValue) === true)
                    {
                        info.newData = null;
                    }
                    else
                    {
                        info.newData = result.name;
                    }
                }
                else
                {
                    info.newData = null;
                }
                return info;
            },
            isSame(a,b)
            {
                return a === b
            },
            async isItActualWithDatabase()
            {
                let dbStatus = {}
                // Check category
                let count = 0;
                if(this.product.category.ref !== null)
                {
                    count++;
                    dbStatus.category = await this.checkIfActual(this.product.category.ref,this.product.category.name)
                }
                if(this.product.manufacturer.ref !== null)
                {
                    count++;
                    dbStatus.manufacturer = await this.checkIfActual(this.product.manufacturer.ref,this.product.manufacturer.name)
                }
                
                if(this.product.storage.warehouse.ref !== null)
                {
                    count++;
                    dbStatus.warehouse = await this.checkIfActual(this.product.storage.warehouse.ref,this.product.storage.warehouse.name)
                }
                for(let i=0; i<this.product.attribs.list.length;i++)
                {
                    count++;
                    let currentAttrib = this.product.attribs.list[i];
                    let attribRefResult = await currentAttrib.ref.get();
                    
                    currentAttrib.exists = attribRefResult.exists;
                }
                
                if(count>0)
                {
                    this.dataCorrectness = dbStatus;
                }
                
            },
            visitExternalSource()
            {
                if(this.product.link.href.length === 0) return;
                window.open(this.product.link.href, '_blank');
            },
            async getProduct()
            {
                if(this.$route.query.id === undefined || this.$route.query.id === null || this.$route.query.id.length === 0)
                {
                    this.$store.commit('setNotification',{
                            show: true,
                            head: "Failed",
                            subheader: `Cannot get this product.`,
                            success: false
                    });
                    this.$router.push("/dashboard/products");
                }
                else
                {
                    try{
                        const queryResult = await this.$store.state.userData.system.ref.collection("Products").doc(this.$route.query.id).get();
                        if(!queryResult.exists)
                        {
                            throw 'Product dont exists!';
                        }
                        else
                        {
                            this.product = queryResult.data();
                            document.title = `${this.product.name} - Complaia`;
                            this.isItActualWithDatabase();
                            this.loading = false;
                        }
                    }
                    catch(error)
                    {
                        console.log(error);
                        this.$store.commit('setNotification',{
                                show: true,
                                head: "Failed",
                                subheader: `Cannot get this product.`,
                                success: false
                        });
                        this.$router.push("/dashboard/products");
                    }
                    
                }
                // this.$store.state.userData.system.ref.collection("Products").doc("")
            }
        }
    }
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>